import Vue from 'vue'
import {
  MdApp,
  MdButton,
  MdCard, MdCheckbox,
  MdContent,
  MdDrawer,
  MdField,
  MdIcon,
  MdList,
  MdToolbar,
  MdTable,
  MdRadio, MdDivider
} from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

import App from './App.vue'

Vue.use(MdButton)
Vue.use(MdApp)
Vue.use(MdToolbar)
Vue.use(MdDrawer)
Vue.use(MdList)
Vue.use(MdContent)
Vue.use(MdCard)
Vue.use(MdField)
Vue.use(MdIcon)
Vue.use(MdCheckbox)
Vue.use(MdTable)
Vue.use(MdRadio)
Vue.use(MdDivider)

new Vue({
  render: h => h(App),
}).$mount('#app')
