<template>
  <div>
    <form @submit.prevent="search">
<!--      <md-radio v-model="type" value="query">EAN</md-radio>-->
<!--      <md-radio v-model="type" value="url">URL</md-radio>-->

<!--      <md-field v-if="type === 'query'">-->
<!--        <md-icon>qr_code</md-icon>-->
<!--        <label>EAN</label>-->
<!--        <md-input v-model="query" :disabled="url"></md-input>-->
<!--        <span class="md-helper-text">z. B. 1234567890123</span>-->
<!--      </md-field>-->

      <md-field v-if="type === 'url'">
        <md-icon>link</md-icon>
        <label>URL</label>
        <md-input v-model="url" type="url"></md-input>
        <span class="md-helper-text">https://geizhals.at/...</span>
      </md-field>

      <md-button type="submit" class="md-raised">
        <md-icon>
          search
        </md-icon>
        <span>
          Suchen
        </span>
      </md-button>
    </form>
  </div>
</template>

<script>
export default {
  data: () => ({
    type: 'url',
    query: null,
    url: null,
  }),
  methods: {
    search() {
      if (this.type === 'url') {
        this.$emit('searchByUrl', this.url);
      }

      if (this.type === 'query') {
        this.$emit('searchByQuery', this.query);
      }
    }
  }
}
</script>
