<template>
  <md-app>
    <md-app-toolbar class="md-primary">
      <span class="md-title">Suche</span>
    </md-app-toolbar>

    <md-app-content>
      <div class="md-layout">
        <md-card class="md-layout-item md-small-size-100">
          <md-card-content>
            <Search @searchByQuery="searchByQuery" @searchByUrl="searchByUrl"/>

            <md-divider></md-divider>

            <md-field>
              <label>Art.-Nr.</label>
              <md-input v-model="itemNumber" required></md-input>
            </md-field>

            <md-field>
              <label>Preis</label>
              <md-input v-model="price" type="text"></md-input>
            </md-field>

            <md-field>
              <label>Statt-Preis</label>
              <md-input v-model="oldPrice" type="text"></md-input>
            </md-field>

            <md-field>
              <label>Produktbezeichnung überschreiben</label>
              <md-input v-model="productName"></md-input>
            </md-field>

            <md-button class="md-raised md-primary" :disabled="!downloadLink" :href="downloadLink" target="_blank" download>
              <md-icon>download</md-icon>
              Download PDF
            </md-button>
          </md-card-content>
        </md-card>

        <md-card class="md-layout-item md-small-size-100">
          <md-card-content v-if="product">
            <md-button class="md-dense md-accent" @click="resetCategory()">Reset category <i>{{ product.category }}</i></md-button>
            <md-button class="md-dense md-accent" @click="resetAllCategories()">Reset all categories</md-button>
            <div v-for="(value, name) in product.attributes" :key="name">
              <md-checkbox v-model="selected" :value="name">
                {{ name }}
              </md-checkbox>
            </div>
          </md-card-content>
        </md-card>

        <md-card class="md-layout-item md-small-size-100">
          <md-card-content v-if="product">
            <Product :product="product" :productName="productName" v-model="selected"/>
          </md-card-content>
        </md-card>
      </div>
    </md-app-content>
  </md-app>
</template>

<script>
import qs from "qs";
import axios from "axios";

import Search from "./Search";
import Product from "./Product";
import {getForCategory, resetAll, resetCategory, setForCategory} from "@/attributeCache";

export default {
  name: 'App',
  components: {
    Search,
    Product
  },

  data: () => ({
    url: '',
    selected: [],
    product: null,
    price: '',
    oldPrice: '',
    itemNumber: '',
    productName: '',
    comments: ''
  }),

  watch: {
    selected(newValue) {
      setForCategory(this.product.category, newValue);
    }
  },

  computed: {
    downloadLink() {
      console.log(this.product, this.url, this.itemNumber, this.selected);
      if (!this.product || !this.url || !this.itemNumber || !this.selected || this.selected.length < 1) {
        return null;
      }

      const params = {
        url: this.url,
        price: this.price,
        oldPrice: this.oldPrice,
        itemNumber: this.itemNumber,
        comment: this.comment,
        attr: this.selected,
      };

      if (this.productName) {
        params.productName = this.productName;
      }

      return axios.getUri({
        url: '/label',
        params,
        paramsSerializer(params) {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          });
        }
      });
    }
  },

  methods: {
    async searchByQuery(query) {
      this.url = `https://geizhals.at/?fs=${query}`;
      const response = await axios.get('/find', {
        params: { url: this.url }
      });

      this.product = response.data;
      this.selected = getForCategory(this.product.category);
    },

    async searchByUrl(url) {
      this.url = url;
      const response = await axios.get('/find', {
        params: { url: this.url }
      });

      this.product = response.data;
      this.selected = getForCategory(this.product.category);
    },

    resetCategory() {
      this.selected = [];
      resetCategory(this.product.category);
    },

    resetAllCategories() {
      this.selected = [];
      resetAll();
    }
  }
}
</script>

