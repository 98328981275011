export function getForCategory(category) {
    return JSON.parse(localStorage.getItem(`category:${category}`)) || [];
}

export function setForCategory(category, attributes) {
    if (category) {
        localStorage.setItem(`category:${category}`, JSON.stringify(attributes));
    }
}

export function resetCategory(category) {
    if (category) {
        localStorage.removeItem(`category:${category}`);
    }
}

export function resetAll() {
    localStorage.clear();
}
