<template>
  <div>
    <h1 v-if="productName">
      {{ productName }}
    </h1>
    <h1 v-else>
      {{ product.name }}
    </h1>
    <div class="product-image">
      <img :src="product.image" alt="product.name">
    </div>
    <md-table>
      <draggable v-model="selected" handle=".drag-cell">
        <md-table-row v-for="attribute in value" :key="attribute" class="row">
          <md-table-head class="drag-cell">
            <md-icon class="icon">drag_handle</md-icon> {{ attribute }}
          </md-table-head>
          <md-table-cell>
            {{ product.attributes[attribute] }}
          </md-table-cell>
        </md-table-row>
      </draggable>
    </md-table>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable
  },
  props: {
    value: Array,
    product: Object,
    productName: String,
  },
  data() {
    return {
      selected: this.value
    };
  },
  watch: {
    selected(val) {
      this.$emit('input', val);
    }
  }
}
</script>

<style scoped>
.product-image {
  text-align: center;
  margin: 2rem;
}

.drag-cell {
  cursor: grab;
}

.drag-cell .icon {
  opacity: 0.3;
}

.drag-cell:hover .icon {
  opacity: 1;
}
</style>
